module.exports = {
    siteMetadata: {
        title: 'sasbeaumont-gatsby',
        siteUrl: 'https://sasbeaumont.com',
        supportedLanguages: ['fr', 'en'],
        defaultLanguage: 'fr',
        routes: {
            en: {
                home: '/home',
                products: '/our-products',
                company: '/company',
                career: '/career',
                contact: '/contact-en',
                terms: '/terms-and-conditions'
            },
            fr: {
                home: '/',
                products: '/nos-produits',
                company: '/entreprise',
                career: '/carrieres',
                contact: '/contact',
                terms: '/mentions-legales'
            }
        }
    },
    plugins: [
        'gatsby-plugin-styled-components',
        {
            resolve: 'gatsby-plugin-google-analytics',
            options: {trackingId: 'UA-159062767-1'}
        },
        'gatsby-plugin-preact',
        'gatsby-plugin-sharp',
        'gatsby-plugin-react-helmet',
        'gatsby-plugin-sitemap',
        {
            resolve: 'gatsby-plugin-manifest',
            options: {icon: 'src/images/icon.png'}
        },
        'gatsby-transformer-sharp',
        {
            resolve: 'gatsby-source-filesystem',
            options: {
                name: 'src',
                path: `${__dirname}/src/`
            }
        },
        {
            resolve: 'gatsby-source-filesystem',
            options: {
                name: 'images',
                path: './src/images/'
            },
            __key: 'images'
        }
    ]
};
