// eslint-disable-next-line no-unused-vars
import React, { createRef } from 'react';
import { I18nextProvider } from 'react-i18next';
import styled, {
    ThemeProvider, createGlobalStyle
} from 'styled-components';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import i18n from '../locales/i18n';
import theme from './../theme';

import { withPageContext } from '../../pageContext';
import { getAlternatePath } from './LocalizedLink';
import Constants from './../constants';
import Header from './Header';
import HeaderMobile from './HeaderMobile';
import Footer from './Footer';

const GlobalStyle = createGlobalStyle`
  ${theme.normalize}
  ${theme.base}
`;
const MainLayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    transition: all 0.4s ease;
    margin-top: ${Constants.headerHeight}px;

    ${(props) => props.theme.media.littleScreen`
        margin-top: 100px;
    `}

    ${(props) => props.theme.media.mobile`
        margin-top: 80px;
    `}
`;

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {sticky: false};
        this.headerMobile = createRef();

        this.scrollListener = this.scrollListener.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scrollListener);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollListener);
    }

    scrollListener() {
        const { sticky } = this.state;
        const shouldBeSticky = window.scrollY > Constants.headerHeight;

        if (sticky !== shouldBeSticky) {
            this.setState({ sticky: shouldBeSticky });
        }
    }

    render() {
        const {
            children, pageContext
        } = this.props;
        const { sticky } = this.state;

        const alternate = getAlternatePath(i18n.language, pageContext.originalPath);

        return (
            <I18nextProvider i18n={i18n}>
                <ThemeProvider theme={theme}>
                    <Helmet>
                        <html lang={i18n.language} />
                        {alternate.map((lang) => (
                            <link rel="alternate"
                                hreflang={lang.hreflang}
                                href={`https://sasbeaumont.com${lang.href}`} />
                        ))}
                    </Helmet>
                    <Header stickyHeader={sticky} />
                    <HeaderMobile ref={this.headerMobile}
                        stickyHeader={sticky} />

                    <MainLayoutContainer>
                        {children}
                    </MainLayoutContainer>
                    <Footer />
                    <GlobalStyle />
                </ThemeProvider>
            </I18nextProvider>
        );
    }
}

Layout.propTypes = {
    pageContext: PropTypes.shape({originalPath: PropTypes.string}),
    children: PropTypes.any
};

export default withPageContext(Layout);
