import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import LocalizedLink from './LocalizedLink';

const Container = styled.nav`
    display: flex;
    flex-wrap: wrap;
`;

const StyledLink = styled(LocalizedLink)`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.white.hex};
    text-decoration: none;
    padding: 5px 0px;
    width: 50%;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.8;
    }


  ${({ theme }) => theme.media.mobile`
    padding: 10px 0px;
  `}
`;

const BottomNavigation = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <StyledLink to="home">
                {t('home')}
            </StyledLink>
            <StyledLink to="company">
                {t('company')}
            </StyledLink>
            <StyledLink to="products">
                {t('products')}
            </StyledLink>
            <StyledLink to="career">
                {t('career')}
            </StyledLink>
            <StyledLink to="contact">
                {t('contact')}
            </StyledLink>
            <StyledLink to="terms">
                {t('terms')}
            </StyledLink>
        </Container>
    );
};

export default BottomNavigation;
