import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { usePageContext } from '../../pageContext';
import LocalizedLink from './LocalizedLink';

const Container = styled.nav`
    display: flex;
    align-items: center;

    ${({ theme }) => theme.media.mobile`
      flex-direction: column;
  `}
`;

const StyledLink = styled(LocalizedLink)`
    font-size: 22px;
    color: ${({ theme }) => theme.colors.black.hex};
    text-decoration: none;
    transition: color 0.2s ease;
    padding: 5px 20px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      background-color: ${({ theme }) => theme.colors.mainBlue.hex};
      left: 50%;
      height: 2px;
      transform: translateX(-50%);
      width: 0%;
      transition: 0.3s ease;
    }

    &:hover, 
    &.active {
        color: ${({ theme }) => theme.colors.mainBlue.hex};
        &:before {
          width: 20%;
        }
    }

    ${({ theme }) => theme.media.littleScreen`
      font-size: 18px;
      padding: 5px 15px;
    `}

    ${({ theme }) => theme.media.mobile`
      margin: 15px 0px;
    `}
`;

const StyledLinkImg = styled(StyledLink)`
  transition: all 0.4s ease;

  padding: 0px 10px;

  &.sticky {
    padding: 5px 10px;
  }

  &:before {
    content: none !important;
  }

  ${({ theme }) => theme.media.mobile`
    display: none;
  `}
`;

const LogoImg = styled.img`
    transition: all 0.4s ease;

    height: ${({ sticky }) => sticky ? 70 : 90}px;

    ${({ theme }) => theme.media.littleScreen`
      height: ${({ sticky }) => sticky ? 60 : 80}px;
    `}
`;

const Navigation = ({ stickyHeader }) => {
    const { t } = useTranslation('translation');
    const { originalPath } = usePageContext();

    let currentPath = originalPath.replace(/\\|\//g, '');
    if (originalPath === '/') {
        currentPath = 'home';
    }

    return (
        <Container>
            <StyledLinkImg to="home"
                className={`${stickyHeader ? 'sticky' : ''}`}>
                <LogoImg src="/images/logo.svg"
                    sticky={stickyHeader}
                    alt="Logo SAS Beaumont" />
            </StyledLinkImg>
            <StyledLink className={currentPath === 'company' ? 'active' : ''}
                to="company">
                {t('company')}
            </StyledLink>
            <StyledLink className={currentPath === 'products' ? 'active' : ''}
                to="products">
                {t('products')}
            </StyledLink>
            <StyledLink className={currentPath === 'career' ? 'active' : ''}
                to="career">
                {t('career')}
            </StyledLink>
            <StyledLink className={currentPath === 'contact' ? 'active' : ''}
                to="contact">
                {t('contact')}
            </StyledLink>
        </Container>
    );
};

Navigation.propTypes = {stickyHeader: PropTypes.bool.isRequired};

export default Navigation;
