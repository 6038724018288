import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import config from '../../gatsby-config';

const SimpleLink = styled(Link)``;
const SimpleA = styled.a``;

export const getLocalizedPath = (language, to) => {
    const path = to.replace(/\\|\//g, '');

    return config.siteMetadata.routes[language][path];
};

export const getAlternatePath = (language, to) => {
    const alternate = config.siteMetadata.supportedLanguages
        .filter((lang) => lang !== language)
        .map((lang) => ({
            href: getLocalizedPath(lang, to),
            hreflang: lang
        }));

    return alternate;
};

const LocalizedLink = ({
    to, ...props
}) => {
    const { i18n } = useTranslation();
    const path = getLocalizedPath(i18n.language, to);
    const pathName = to.replace(/\\|\//g, '');

    if (to.indexOf('http') !== -1) {
        return <SimpleA
            aria-label={'Link to external url'}
            {...props}
            href={to}>
            {props.children}
        </SimpleA>;
    }

    return <SimpleLink
        aria-label={`Link to ${pathName} in ${i18n.language}`}
        {...props}
        to={path}>
        {props.children}
    </SimpleLink>;
};

LocalizedLink.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.any
};

export default React.memo(LocalizedLink);
