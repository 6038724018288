import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Constants from '../constants';
import Navigation from './Navigation';
import LangSwitcher from './LangSwitcher';

const Container = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  background-color: ${({ theme }) => theme.colors.white.hex};
  transition: all 0.4s ease;
  z-index: 9;
  
  ${({
        sticky, theme
    }) => sticky ? `
    box-shadow: 0px 2px 4px rgba(${theme.colors.black.rgb}, 0.2);
  ` : `border-bottom: 1px solid rgba(${theme.colors.mainBlue.rgb}, 0.15);`}

  ${({ theme }) => theme.media.mobile`
      display: none;
  `} 
`;

const ContentContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.4s ease;
  min-height: ${({ sticky }) => sticky ? Constants.headerSticky : Constants.headerHeight}px;

  ${({ theme }) => theme.media.littleScreen`
    width: calc(100% - 10px);
    padding-right: 10px;
    min-height: ${({ sticky }) => sticky ? 100 : 110}px;
  `}
`;

const Header = ({ stickyHeader }) => (
    <Container sticky={stickyHeader}>
        <ContentContainer sticky={stickyHeader}>
            <Navigation stickyHeader={stickyHeader} />
            <LangSwitcher />
        </ContentContainer>
    </Container>
);

Header.propTypes = {stickyHeader: PropTypes.bool.isRequired};

export default Header;
