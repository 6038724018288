import base from './base';
import colors from './colors';
import normalize from './normalize';
import media from './responsive';

const theme = {
    normalize,
    base,
    colors,
    media
};

export default theme;
