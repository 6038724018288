import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import BottomNavigation from './BottomNavigation';
import LocalizedLink from './LocalizedLink';

const Container = styled.footer`
  background-color: ${({ theme }) => theme.colors.mainBlue.hex};
  min-height: 80px;
  margin-top: auto;
  position: relative;
  padding: 40px 0px;
`;

const Content = styled.section`
  max-width: 1280px;
  margin: 0 auto;
  width: 80%;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.media.littleScreen`
    width: calc(100% - 30px);
  `}

  ${({ theme }) => theme.media.mobile`
    width: calc(100% - 10px);
    flex-direction: column;
  `}
`;

const LinkSubtitle = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white.hex};
  margin-bottom: 10px;

  ${({ theme }) => theme.media.mobile`
      display: none;
  `}
`;

const Block = styled.div`
  display: flex;
`;

const SocialLinks = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.mobile`
    flex-direction: row;
    justify-content: space-around;
    margin: 20px 0px;
  `}
`;

const SocialLink = styled.a`
  font-size: 14px;
  padding: 5px 0px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white.hex};
  display: flex;
  align-items: center;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }
`;

const Copyright = styled.span`
  position: absolute;
  bottom: 10px;
  left: 50%;
  font-size: 12px;
  transform: translate(-50%, 0%);
  color: ${({ theme }) => theme.colors.white.hex};
  text-align: center;
`;

const LinkImg = styled(LocalizedLink)`
  transition: all 0.4s ease;

  padding: ${({ sticky }) => sticky ? '5px' : '0px'}  20px;
`;

const LogoImg = styled.img`
    height: 90px;
`;

const Footer = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <Content>
                <Block>
                    <LinkImg to="home">
                        <LogoImg src="/images/logo-white.svg"
                            alt="Logo SAS Beaumont" />
                    </LinkImg>
                    <BottomNavigation />
                </Block>
                <SocialLinks>
                    <LinkSubtitle>
                        {t('learn_more')}
                    </LinkSubtitle>
                    <SocialLink rel="noreferrer"
                        href="https://www.linkedin.com/company/sas-beaumont/"
                        target="_blank">
                        Linkedin
                    </SocialLink>
                    <SocialLink rel="noreferrer"
                        href="https://www.lafrenchfab.fr/entreprise/sas-beaumont/"
                        target="_blank">
                        French Fab
                    </SocialLink>
                </SocialLinks>
            </Content>
            <Copyright>
                ©
                {' '}
                {new Date().getFullYear()}
                {' '}
                SAS Beaumont - 9 Cours Borodine - 26000 Valence
            </Copyright>
        </Container>
    );
};

export default Footer;
