import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { usePageContext } from '../../pageContext';
import config from '../../gatsby-config';

const Container = styled.div`
    display: flex;
    justify-content: space-around;
    background-color: ${({ theme }) => theme.colors.mainBlue.hex};
    padding: 10px;
    border-radius: 25px;
    min-width: 120px;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${({ theme }) => theme.colors.white.hex};
        width: 2px;
        height: 60%;
    }

    ${({ theme }) => theme.media.littleScreen`
      padding: 8px 4px;
    `}

    ${({ theme }) => theme.media.mobile`
      width: 33%;
    `}
`;

const LinkLanguage = styled(Link)`
    font-size: 18px;
    color: ${({ theme }) => theme.colors.white.hex};
    background-color: ${({ theme }) => theme.colors.mainBlue.hex};
    border: none;
    outline: none;
    margin: 0px 5px;
    padding: 1px 0px;
    cursor: pointer;
    font-weight: 300;
    text-transform: uppercase;
    text-decoration: none;

    ${({ active }) => (active ? `
        font-weight: bold;
    ` : null)}
`;

const LangSwitcher = () => {
    const { i18n } = useTranslation();
    const { originalPath } = usePageContext();

    const getLinkForLang = (lang) => {
        const path = originalPath.replace(/\\|\//g, '');
        if (originalPath === '/') {
            return config.siteMetadata.routes[lang].home;
        }

        return config.siteMetadata.routes[lang][path];
    };

    const renderLanguages = () => {
        return config.siteMetadata.supportedLanguages.map(lang => (
            <LinkLanguage
                key={lang}
                active={i18n.language === lang}
                to={getLinkForLang(lang)}
            >
                {lang}
            </LinkLanguage>
        ));
    };

    return (
        <Container>
            {renderLanguages()}
        </Container>
    );
};

export default LangSwitcher;
