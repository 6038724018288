import React, {
    useState, forwardRef, useImperativeHandle
} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Navigation from './Navigation';
import LangSwitcher from './LangSwitcher';
import LocalizedLink from './LocalizedLink';

const Container = styled.header`
  display: none;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: ${({ theme }) => theme.colors.white.hex};
  transition: all 0.4s ease;
  z-index: 9;
  
  ${({
        sticky, theme
    }) => sticky ? `
    box-shadow: 0px 2px 4px rgba(${theme.colors.black.rgb}, 0.2);
  ` : `border-bottom: 1px solid rgba(${theme.colors.mainBlue.rgb}, 0.15);`}

  ${({ theme }) => theme.media.mobile`
    display: flex;
    min-height: 60px;
    flex-direction: column;
  `}
`;

const TopContainer = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  width: calc(100% - 10px);
  padding: 0px 10px;
`;

const MenuContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.white.hex};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const BurgerButton = styled.span`
  height: 4px;
  width: 28px;
  background-color: ${({ theme }) => theme.colors.mainBlue.hex};
  border-radius: 2px;
  margin-left: 10px;
  position: relative;
  margin-right: 20px;
  transition: 0.4s;

  &:before, 
  &:after {
    content: "";
    position: absolute;
    width: 30px;
    height: 4px;
    background-color: inherit;
    border-radius: inherit;
    transition: 0.4s;
    transform-origin: center;
  }

  &:before {
    top: -8px;
  }

  &:after {
    bottom: -8px;
  }

  ${({ close }) => close ? `
    height: 0px;

    &:before {
      transform: rotate(45deg);
      top: 0px;
    }
  
    &:after {
      transform: rotate(-45deg);
      bottom: -4px
    }
  ` : null}
`;

const StyledLinkImg = styled(LocalizedLink)`
  height: 100%;
  padding: 5px 0px;
  display: flex;
  align-items: center;
`;

const LogoImg = styled.img`
    height: 56px;
`;

const Header = forwardRef(({ stickyHeader }, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({setIsOpen(open) {
        setIsOpen(open);
    }}));

    return (
        <Container sticky={stickyHeader}>
            <TopContainer>
                <StyledLinkImg to="home">
                    <LogoImg src="/images/logo.svg"
                        alt="Logo SAS Beaumont" />
                </StyledLinkImg>
                <BurgerButton close={isOpen}
                    onClick={() => setIsOpen(!isOpen)} />
            </TopContainer>
            {isOpen ? (
                <MenuContainer>
                    <Navigation stickyHeader={stickyHeader} />
                    <LangSwitcher />
                </MenuContainer>
            ) : (
                null
            )}
        </Container>
    );
});

Header.propTypes = {stickyHeader: PropTypes.bool.isRequired};

export default React.memo(Header);
