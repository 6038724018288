import styled from 'styled-components';

export const PageTitle = styled.h1`
    font-size: 48px;
    color: ${({ theme }) => theme.colors.mainBlue.hex};
    font-weight: bold;
    text-align: center;
`;

export const PageSubTitle = styled.h2`
    font-size: 32px;
    color: ${({ theme }) => theme.colors.mainBlue.hex};
    font-weight: bold;
    padding: 15px;
    margin: 0px;
`;

export const BlockTitle = styled.h3`
    font-size: 28px;
    color: ${({ theme }) => theme.colors.mainBlue.hex};
    font-weight: bold;
    padding: 15px;
    margin: 0px;
`;

export const MainText = styled.p`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.black.hex};
    padding: 15px;
    margin: 0px;
    line-height: 22px;
    white-space: pre-wrap;
`;
