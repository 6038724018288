export default {
    black: {
        hex: '#000',
        rgb: '0, 0, 0'
    },
    white: {
        hex: '#fff',
        rgb: '255, 255, 255'
    },
    mainBlue: {
        hex: '#014656',
        rgb: '1, 70, 86'
    },
    grey: {
        hex: '#E5E5E5',
        rgb: '229, 229, 229'
    },
    darkGrey: {
        hex: '#A0A0A0',
        rgb: '160, 160, 160'
    },
    error: {
        hex: '#cc0000',
        rgb: '204, 0, 0'
    },
    valid: {
        hex: '#00cc00',
        rgb: '0, 204, 0'
    }
};
